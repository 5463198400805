<template>
	<div class="task-block" :class="{ done: block.done === 1 }">
		<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" :disabled="!block.frequency">
			<div class="checkbox-task" :class="{ disabled: block.frequency, error: errorMsg }" @click="handleDoneTask">
				<icon-check />
			</div>

			<template slot="popover">
				<div class="title">{{ $t("projects.done_disabled") }}</div>
			</template>
		</v-popover>
		<div class="text-task block" ref="editableInputTask" v-bind="$attrs" v-on="$listeners"></div>
		<div class="task-actions">
			<div class="action-item" v-if="block.frequency">
				<v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{ show: 0, hide: 0 }">
					<button class="disabled">
						<div class="icon">
							<icon-repeat />
						</div>
					</button>
					<template slot="popover">
						<div class="title">{{ $t("projects.repetitive_task") }}</div>
					</template>
				</v-popover>
			</div>
			<div class="error-msg" v-if="errorMsg">
				Nu are responsabil!
			</div>
			<div class="action-item">
				<div class="filter-dropdown-header avatars-dropdown" id="boxDropdownAvatars">
					<button id="dropdownAvatars" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<div class="icon">
							<img class="avatar" :src="responsible.avatar" v-if="responsible" />
							<icon-user v-else />
						</div>
					</button>

					<div class="dropdown-menu" aria-labelledby="dropdownAvatars">
						<div class="search-filter-dropdown">
							<input type="text" v-model="searchUsers" :placeholder="$t('add_task.choose_responsible')" />
						</div>

						<template v-for="user in filteredUsers">
							<div class="dropdown-item" :class="{ selected: responsible == user }" @click="selectResponsible(user)">
								<img class="avatar" :src="user.avatar" />
								<span class="name">{{ user.name }}</span>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="action-item">
				<div class="filter-dropdown-header icon-dropdown" id="boxDropdownPriority">
					<button id="dropdownPriority" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<div class="icon"><icon-flag :class="`icon ${levelOfPriority.color ? levelOfPriority.color : ''}`" /></div>
					</button>
					<div class="dropdown-menu icon-options" aria-labelledby="dropdownPriority">
						<template v-for="option in optionsPriority">
							<div class="dropdown-item" :class="{ selected: levelOfPriority == option }" @click="selectPriority(option)">
								<div class="icon">
									<icon-flag class="icon" :class="option.color" />
								</div>
								<div class="text">{{ option.name }}</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="action-item">
				<div class="deadline-calendar">
					<FunctionalCalendar ref="CalendarDateOfEmployment" v-model="deadline" class="calendar-tbf" :configs="calendarConfigs" @dayClicked="selectDeadline">
						<template v-slot:datePickerInput="props">
							<div class="vfc-single-input custom-placeholder-picker" v-if="!deadline.selectedDate"><icon-calendar class="icon" /></div>
							<div class="selected-deadline" v-else>{{ moment(props.selectedDate, "D/M/YYYY").format("MMM DD") }}</div>
						</template>
					</FunctionalCalendar>
				</div>
			</div>
			<div class="action-item">
				<button class="b-pad-0" @click="openModalTask()">
					<div class="icon">
						<icon-dots />
					</div>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import IconCheck from "@/components/Icons/Check";
import IconUser from "@/components/Icons/User";
import IconFlag from "@/components/Icons/Flag";
import IconRepeat from "@/components/Icons/Repeat";
import IconDots from "@/components/Icons/EditDots";
import IconCalendar from "@/components/Icons/Calendar";

import { debounce } from "debounce";

export default {
	inheritAttrs: false,
	components: {
		IconCheck,
		IconUser,
		IconFlag,
		IconDots,
		IconRepeat,
		IconCalendar,
	},
	props: {
		block: Object,
	},
	watch: {
		block: {
			handler(val) {
				this.responsible = this.block.responsible_id ? this.users.find((el) => el.id == this.block.responsible_id) : "";
				this.levelOfPriority = this.block.priority ? this.optionsPriority.find((el) => el.key == this.block.priority) : "";
				this.isDone = this.block.done;
				this.deadline.selectedDate = this.block.date ? moment(this.block.date, "YYYY-MM-DD").format("D/M/YYYY") : "";
			},
		},
	},
	data() {
		return {
			users: this.$store.state.blocks.users,
			responsible: "",
			searchUsers: "",
			optionsPriority: [
				{ key: "urgent", name: "Urgent", color: "red" },
				{ key: "important", name: "Important", color: "yellow" },
				{ key: "normal", name: "Normal", color: "blue" },
			],
			levelOfPriority: "",
			isDone: 0,
			calendarConfigs: {
				isDatePicker: true,
				isDateRange: false,
				isModal: true,
				isAutoCloseable: false,
				changeMonthFunction: true,
				changeYearFunction: true,
				disabledDates: ["beforeToday"],
			},
			deadline: {
				currentDate: moment(),
				selectedDate: "",
				multipleDateRange: [],
				selectedDates: [],
				selectedDateTime: false,
				selectedDatesItem: "",
				selectedHour: "00",
				selectedMinute: "00",
				dateRange: { end: "", start: "" },
			},
			errorMsg: false,
		};
	},
	computed: {
		filteredUsers() {
			return getByKeywordFilter(this.users, this.searchUsers);
		},
	},
	created() {
		this.debouncedHandleDoneTask = debounce((data) => {
			this.checkTask(data);
		}, 500);
	},
	mounted() {
		this.responsible = this.block.responsible_id ? this.users.find((el) => el.id == this.block.responsible_id) : "";
		this.levelOfPriority = this.block.priority ? this.optionsPriority.find((el) => el.key == this.block.priority) : "";

		if (this.block.date) {
			this.deadline.selectedDate = this.block.date ? moment(this.block.date, "YYYY-MM-DD").format("D/M/YYYY") : "";
		}

		this.isDone = this.block.done;
	},
	methods: {
		selectResponsible(user) {
			this.responsible = user;
			this.updateTask(true);

			if (this.errorMsg) {
				this.errorMsg = false;
			}
		},
		selectPriority(optionSelected) {
			this.levelOfPriority = this.levelOfPriority == optionSelected ? "" : optionSelected;
			this.updateTask(true);
		},
		selectDeadline(deadlineData) {
			this.deadline.selectedDate = deadlineData.date;
			this.updateTask(true);
		},
		handleDoneTask() {
			if (this.block.responsible_id) {
				if (this.block.frequency) {
					return true;
				} else if (this.block.task_id) {
					var newValueDone = this.block.done === 1 ? 0 : 1;
					var newDate = "";

					if (!this.block.date) {
						newDate = moment().format("YYYY-MM-DD");
					} else {
						newDate = moment(this.block.date).format("YYYY-MM-DD");
					}

					this.$store.dispatch("blocks/editBlock", { ...this.block, done: newValueDone, date: newDate });

					this.debouncedHandleDoneTask({ date: newDate, done: newValueDone });
				}
			} else {
				this.errorMsg = true;
			}
		},
		checkTask(formData) {
			axios.post(`/tasks/${this.block.task_id}/check`, formData).then(({ data }) => {
				this.$store.dispatch("blocks/editBlock", { ...this.block, task_log_id: data.data.task_log_id });
			});
			this.$emit("updateProject");
		},
		updateTask(instantUpdate = false) {
			var newBlock = {
				...this.block,
				responsible_id: this.responsible ? this.responsible.id : "",
				priority: this.levelOfPriority ? this.levelOfPriority.key : "",
				done: this.isDone,
			};

			if (this.deadline.selectedDate) {
				newBlock.date = moment(this.deadline.selectedDate, "D/M/YYYY").format("YYYY-MM-DD");
			}

			this.$store.dispatch("blocks/editBlock", newBlock);
			this.$emit("update-task", instantUpdate);
		},
		openModalTask() {
			if (this.block.task_id) {
				var dataModal = {
					crud: "edit",
					taskEditId: this.block.task_id,
					// type: this.block.hasOwnProperty('task_log_id') && this.block.task_log_id ? 'task_log' : 'task',
					date: this.block.date ? this.block.date : "",
					from: this.block.date ? "" : "project",
				};

				this.$root.$emit("open_modal", "add_task", dataModal, false);
			}
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>
